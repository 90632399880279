import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Products from '../components/Products';

const keywords = `metals valley, uae, middle east,trading, metals scrap, metal trading, dubai, sharjah,
  abu dhabi, united arab emirates, trade, aluminum trading, zinc,
  copper, trading, marketing, best metal trading, scrap metal`;

const ProductsPage = () => (
  <Layout>
    <SEO title="Products" keywords={keywords} />
    <Products />
  </Layout>
);

export default ProductsPage;
