import React from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

// eslint-disable-next-line react/prop-types
const Trigger = ({ title, open }) => (
  <div className="px-3 py-5 bg-blue-600 rounded-t text-white flex justify-between items-center cursor-pointer">
    <p className="m-0 text-lg uppercase font-semibold">{title}</p>
    {open ? (
      <FaChevronUp className="inline-block text-2xl" />
    ) : (
      <FaChevronDown className="inline-block text-2xl" />
    )}
  </div>
);

const CollapsibleText = ({ title, contents, imgsrc }) => {
  return (
    <Collapsible
      transitionTime={150}
      trigger={<Trigger title={title} />}
      triggerWhenOpen={<Trigger title={title} open />}
    >
      <div className="text-gray-100 bg-blue-500 px-5 py-3 flex flex-wrap justify-center items-center">
        <img
          className="w-56 h-56 rounded-full mx-auto"
          src={imgsrc}
          alt={title}
        />
        <div className="w-full md:w-40">
          {contents.map(content => (
            <p className="text-md font-medium m-0 capitalize text-center lg:text-left">
              {content}
            </p>
          ))}
        </div>
      </div>
    </Collapsible>
  );
};
CollapsibleText.defaultProps = {
  contents: [],
  imgsrc: ``,
};

CollapsibleText.propTypes = {
  title: PropTypes.string.isRequired,
  imgsrc: PropTypes.string,
  contents: PropTypes.arrayOf(PropTypes.string),
};
export default CollapsibleText;
