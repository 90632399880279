import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Animated } from 'react-animated-css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import Container from './Container';
import TitleCard from './TitleCard';
import { PhotoCover } from './PhotoCover';
import CollapsibleText from './CollapsibleText';

const ProductsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
`;

const StyledCarousel = styled(Carousel)`
  flex: 1;
  box-sizing: border-box;

  @media (max-width: 990px) {
    flex-basis: 100%;
    overflow: hidden;
  }
`;

const StyledText = styled.div`
  flex: 1;
`;

const Row = styled.div`
  padding: 0;
  margin: 0;

  && :not(last-child) {
    border-bottom: 2px solid #fff;
  }
`;

const Products = () => {
  const data = useStaticQuery(graphql`
    query {
      prodcover: file(relativePath: { eq: "copper-prod.jpg" }) {
        publicURL
      }
      aluminum: file(relativePath: { eq: "prod-1.jpg" }) {
        publicURL
      }
      prod2: file(relativePath: { eq: "prod-2.jpg" }) {
        publicURL
      }
      brasshoney: file(relativePath: { eq: "prod-4.jpg" }) {
        publicURL
      }
      copperprod: file(relativePath: { eq: "copper-prod.jpg" }) {
        publicURL
      }
      copper: file(relativePath: { eq: "copper-2.jpg" }) {
        publicURL
      }
      lead: file(relativePath: { eq: "lead.jpg" }) {
        publicURL
      }
      zinc: file(relativePath: { eq: "zinc.jpg" }) {
        publicURL
      }
    }
  `);

  return (
    <section style={{ overFlow: 'hidden' }} className="mx-auto pb-10">
      <PhotoCover src={data.prodcover.publicURL} />

      <div className="pt-1">
        <Animated animationIn="fadeInDown" animationInDuration={1400}>
          <TitleCard title="Products" />
        </Animated>
      </div>

      <Container>
        <div className="mt-8 py-6 px-5 text-2xl text-center text-white bg-blue-600">
          We supply high quality materials from Middle East and Africa within
          the fair market price.
        </div>

        <ProductsGrid>
          <StyledCarousel
            showStatus={false}
            showArrows={false}
            autoPlay
            infiniteLoop
          >
            <div>
              <img alt="aluminum" src={data.aluminum.publicURL} />
            </div>
            <div>
              <img alt="prod2" src={data.prod2.publicURL} />
            </div>
            <div>
              <img alt="brasshoney" src={data.brasshoney.publicURL} />
            </div>
            <div>
              <img alt="copperprod" src={data.copperprod.publicURL} />
            </div>
            <div>
              <img alt="lead" src={data.lead.publicURL} />
            </div>
            <div>
              <img alt="zinc" src={data.zinc.publicURL} />
            </div>
          </StyledCarousel>
          <StyledText className="pt-8  pl-8 pr-5 text-gray-900">
            <p className="text-2xl border-b-2 mb-0 mt-2 border-blue-600 font-bold text-left">
              Non-ferrous metals
            </p>
            <div className="mt-6">
              <Row>
                <CollapsibleText
                  title="copper"
                  imgsrc={data.copper.publicURL}
                  contents={[
                    'Cu BSM',
                    'Cu Berry / Candy',
                    'Cu Birch Cliff',
                    'Cu Ingots',
                  ]}
                />
              </Row>
              <Row>
                <CollapsibleText
                  imgsrc={data.brasshoney.publicURL}
                  title="brass"
                  contents={['Br Honey', 'Br Ingots', 'Br Ocean']}
                />
              </Row>
              <Row>
                <CollapsibleText
                  imgsrc={data.aluminum.publicURL}
                  title="aluminum"
                  contents={[
                    'Al Clean Profile',
                    'al mill finish',
                    'al mix profile',
                    'al talon',
                    'al talk + fe',
                    'al tense',
                    'al trump',
                    'al wheels',
                    'al t/tabor',
                    'al utensils',
                    'al radiator',
                    'al ingots',
                  ]}
                />
              </Row>
              <Row>
                <CollapsibleText
                  imgsrc={data.lead.publicURL}
                  title="lead"
                  contents={['lead ingots', 'pb rains']}
                />
              </Row>
              <Row>
                <CollapsibleText
                  imgsrc={data.zinc.publicURL}
                  title="zinc"
                  contents={['zinc die cast', 'zinc ingots']}
                />
              </Row>
            </div>
          </StyledText>
        </ProductsGrid>
      </Container>
    </section>
  );
};
export default Products;
